import { Suspense } from 'react';
import { BrowserRouter } from '@leagueplatform/routing';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';

import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  FullPageError,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
// import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';
import League, { LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { config } from 'init-config';
// import { CustomFormsCenterCardGrid } from 'components/form-center/custom-forms-center-card.component';
import AppRoutes from './components/AppRoutes';

League.overwriteRoutePaths({
  [LEAGUE_MODULE_NAMES.assessment]: '/member/health-profile/questions',
  [LEAGUE_MODULE_NAMES.aboutMe]: '/member/about-me',
  [LEAGUE_MODULE_NAMES.healthProfile]: '/member/health-profile',
  [LEAGUE_MODULE_NAMES.healthNeedPages]: '/member/health-need',
  [LEAGUE_MODULE_NAMES.onboarding]: '/member/onboarding',
  [LEAGUE_MODULE_NAMES.wallet]: '/benefits',
  [LEAGUE_MODULE_NAMES.careDocuments]: '/care/documents',
  [LEAGUE_MODULE_NAMES.messaging]: '/messaging',
});

export function App() {
  return (
    <League.LeagueProvider config={config} treatConfigAsInitialOnly>
      <ErrorBoundary fallback={<ErrorState />}>
        <Suspense fallback={<LoadingIndicator />}>
          {useOnlineStatus().isOffline && <FullPageError route="/home" />}
          {useOnlineStatus().isOnline && (
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
