import {
  HeadingText,
  ParagraphText,
  Modal,
  ImageMessage,
  useMediaQuery,
  queryHelpers,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import errorImg from 'assets/error.png';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import { ModalActionButton } from './common/modal-action-button.component';

export const AccountDeletedModal = () => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  return (
    <BaseModalContainer
      footer={
        <ModalActionButton
          actionType="submit"
          labelId="I_UNDERSTAND"
          isMobile={isMobile}
          cssStyle={{
            background: '$interactiveActionPrimary',
            alignSelf: 'flex-end',
            marginBlockStart: '$two',
          }}
        />
      }
    >
      <ImageMessage
        image={errorImg}
        imageWidth={146}
        css={{ flexGrow: 1, padding: '$two $none' }}
      >
        {/* @ts-ignore */}
        <Modal.Title>
          <HeadingText
            level="2"
            size="lg"
            css={{ marginBlock: '$one', fontWeight: 'bold' }}
          >
            {formatMessage({ id: 'ACCOUNT_DELETED_TITLE' })}
          </HeadingText>
        </Modal.Title>
        <ParagraphText>
          {formatMessage({ id: 'ACCOUNT_DELETED_BODY_1' })}
        </ParagraphText>
        <ParagraphText>
          {formatMessage({ id: 'ACCOUNT_DELETED_BODY_2' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};
