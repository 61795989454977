import React from 'react';
import { UserDropdownComponent } from '@leagueplatform/web-common-components';
import {
  SCREEN_NAMES,
  PRODUCT_AREA,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { useUserDropdownComponent } from 'components/header-nav/navbar/user-dropdown/user-dropdown.hook';
import { getUserOptionAnalyticsProps } from 'utils/analytics-events';

interface Props {
  handleDeleteAccount: () => void;
}

const userBarAnalytics = {
  name: EVENT_NAME.BUTTON_CLICKED,
  props: {
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.HOME,
    detail: 'open settings menu',
  },
};

export const UserDropdown = ({ handleDeleteAccount }: Props) => {
  const { data } = useUserDropdownComponent(handleDeleteAccount);

  return (
    <UserDropdownComponent
      label={data.preferredFirstName.toUpperCase()}
      dropdownItemsConfig={data.menuItemsConfig.map((section) => section.items)}
      userBarAnalyticsProps={userBarAnalytics}
      getDropdownItemAnalyticsProps={getUserOptionAnalyticsProps}
    />
  );
};
