import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';

export const useUserDropdownComponent = (handleDeleteAccount: () => void) => {
  const { data, isLoading, error } = usePreferredFirstName();
  const userDropdownConfig = useUserDropdownConfig(handleDeleteAccount);
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  const userNavLargeConfig = [...userDropdownConfig]?.filter(
    (item) => item.heading !== 'PRIVACY_AND_LEGAL_DOCUMENTS',
  );

  const dropdownMenuItemProps = {
    preferredFirstName: data.preferredFirstName,
    menuItemsConfig: isMobile ? userDropdownConfig : userNavLargeConfig,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
