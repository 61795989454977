import { useEffect } from 'react';
import { StandaloneAuth } from '@leagueplatform/auth-standalone';
import { LoadingIndicator } from '@leagueplatform/web-common-components';

export const AuthViewLogout = () => {
  useEffect(() => {
    StandaloneAuth.client.logout({
      returnTo: window.location.origin,
    });
  }, []);

  return <LoadingIndicator />;
};

export const AuthViewLogin = () => {
  useEffect(() => {
    StandaloneAuth.client.loginWithRedirect();
  }, []);

  return <LoadingIndicator />;
};
