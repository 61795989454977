import * as React from 'react';
// import { useLocation } from '@leagueplatform/routing';
import { useScrollTopOnLocationChange } from '@leagueplatform/web-common';
// import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import {
  Box,
  GDSStyleObject,
  // queryHelpers,
  // useMediaQuery,
} from '@leagueplatform/genesis-core';
import { APP_NAV_DEFAULT_HEIGHT } from './header-nav/navbar/nav.constants';
import { AppNavBarComponent } from './header-nav/navbar/nav.component';

interface Props {
  isPrivatePage?: boolean;
  children: React.ReactNode;
  css?: GDSStyleObject;
}

export const BasePage = ({
  children,
  isPrivatePage = true,
  css,
  ...boxProps
}: Props) => {
  useScrollTopOnLocationChange();
  // const location = useLocation();
  // const isMobile = useMediaQuery(queryHelpers.between([0, 'tablet']));

  // const isClaims =
  //   location?.pathname === getModulePath(LEAGUE_MODULE_NAMES.claims);

  return (
    <>
      {isPrivatePage && <AppNavBarComponent />}
      <Box
        as="main"
        css={{
          overflowX: 'hidden',
          position: 'relative',
          flex: '1',
          // paddingLeft: isClaims || isMobile ? '$none' : '$five',
          minHeight: `calc(100vh - ${APP_NAV_DEFAULT_HEIGHT}px)`,
          // '> div': {
          //   marginLeft: '$none',
          //   '> div': {
          //     [`@media screen and (min-width: 768px) and (max-width: 1400px)`]:
          //       {
          //         paddingLeft: '56px',
          //       },
          //   },
          // },
          ...css,
        }}
        {...boxProps} // eslint-disable-line
      >
        {children}
      </Box>
    </>
  );
};
