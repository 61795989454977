export const FAQ = '';

export const TERMS_CONDITION_LINK = `/terms-of-use`;
export const PRIVACY_POLICY_LINK = `/privacy-policy`;
export const TERMS_OF_USE_ROUTE = `/home`; 
export const PRIVACY_POLICY_ROUTE = `/home`; 
export const FORMS_CENTER_ROUTE = `/forms-center`;
export const HOME = `/home`;

export const CARE_ADVISOR_EMAIL = 'careadvisorconnect@optum.com';
export const CARE_ADVISOR_PHONE_NUMBER = '866-658-4644';
export const VIEW_HEALTH_INFORMATION_AUTHORIZATION_PATH =
  '/view-health-authorization';

export const NPSSurveyConfig = {
  interceptId: 'SI_2nKmq5YVHcToqma',
  brandId: 'uhg1',
  projectId: 'ZN_3WAcqHSsRfyx3b8',
};

export const MASONRY = {
  API_VERSION: 1,
  CUSTOM_WIDGET_TYPE_HEALTHLINK_LOGO: 'healthlink_logo',
  HEALTH_JOURNEY_MODULE_KEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  MESSAGING_MODULE_KEY: 'messaging',
  CUSTOM_WIDGET_TYPE_MESSAGING: 'recent_messages',
  MODULE_KEY_CLAIMS: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
};

export const MESSAGE_TYPES = {
  GET_APP_INIT_DETAILS: 'get_app_init_details',
  GET_WIDGET_CONTAINER: 'get_widget_container',
};

export const CALLBACK_STATUS = {
  QUEUED: 'QUEUED',
  COMPLETED: 'COMPLETED',
};
