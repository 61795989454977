import {
  remoteConfig,
  REMOTE_CONFIG_DEFAULT_VALUES,
  FEATURE_FLAGS,
} from 'common/firebase';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { useQuery } from 'react-query';

type FeatureFlagValue = boolean | string | number | object;
type FeatureFlagsReturn = Record<FEATURE_FLAGS, FeatureFlagValue>;

interface UseFeatureFlagsReturn {
  featureFlags: FeatureFlagsReturn | undefined;
  isLoading: boolean;
}

const parseFlagValue = (value: string): FeatureFlagValue => {
  try {
    return JSON.parse(value);
  } catch {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return !Number.isNaN(Number(value)) ? Number(value) : value;
  }
};

export const useFeatureFlags = (
  flags: FEATURE_FLAGS[],
): UseFeatureFlagsReturn => {
  const { data: featureFlags, isLoading } = useQuery<FeatureFlagsReturn>(
    ['featureFlags', ...flags],
    async () => {
      // Your fetching logic remains the same
      await fetchAndActivate(remoteConfig);
      return flags.reduce((acc, flag) => {
        const valueString =
          getValue(remoteConfig, flag).asString() ||
          REMOTE_CONFIG_DEFAULT_VALUES[flag];
        acc[flag] = parseFlagValue(valueString);
        return acc;
      }, {} as FeatureFlagsReturn);
    },
    {
      initialData: flags.reduce((acc, flag) => {
        acc[flag] = parseFlagValue(REMOTE_CONFIG_DEFAULT_VALUES[flag]);
        return acc;
      }, {} as FeatureFlagsReturn),
    },
  );

  return {
    featureFlags,
    isLoading,
  };
};
